import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
const IndexPage = ({ data }) => {
    const {
        srcWebp,
        srcSetWebp,
        srcSet,
        src,
        presentationHeight,
        presentationWidth,
        } = data.file.childImageSharp.fluid;
        return (
          <Layout>
           <div id="wrapper">
		        <div id="main">
			       <div class="inner">
                <amp-img src={srcWebp} 
                      srcset={srcSetWebp} 
                      width={presentationWidth} 
                      height={presentationHeight} 
                      alt="Русдосуг" 
                      layout="intrinsic">
                 <div fallback>    
                <amp-img src={src} 
                      srcset={srcSet} 
                      width={presentationWidth} 
                      height={presentationHeight} 
                      alt="Русдосуг" 
                      layout="fill"/> 
                      </div>
                      </amp-img>
                      <p class="style4">Не можете зайти на РусДосуг? Воспользуйтесь нашей страницей!</p>
				<h1 class="style1">Для входа на <strong>RusDosug</strong> нажмите кнопку</h1>
				<ul class="style1 buttons">
					<li><a href="https://mofodotifo.net/in.htm?wm=443696182" class="button n01">Перейти на сайт 18+</a></li>
				</ul>
				<p class="style3"><em>Сохраните эту страницу в закладках своего браузера для постоянного доступа к актуальной ссылке</em></p>
				<ul id="icons01" class="style2 icons">
              <li>
                  <a href="https://www.facebook.com/dosugru.net" aria-label="Подписаться в Facebook">
                    <svg viewBox="0 0 40 40" display="none" width="0" height="0" xmlns="http://www.w3.org/2000/svg">
                      <path d="M27.2,4.7v4.9h-2.9c-1.1,0-1.8,0.2-2.1,0.6c-0.4,0.5-0.6,1.1-0.6,2v3.5H27l-0.7,5.4h-4.7v14H16v-14h-4.7v-5.4H16v-4.1 c0-2.3,0.6-4.1,1.9-5.3s2.9-1.9,5.2-1.9C24.8,4.4,26.2,4.5,27.2,4.7L27.2,4.7z" fill="#FFB5B5"></path>
                    </svg><span class="label">Facebook</span>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/DosugczOfficial" aria-label="Подписаться в Twitter">
                    <svg viewBox="0 0 40 40" display="none" width="0" height="0" xmlns="http://www.w3.org/2000/svg">
                      <path d="M30.1,4h5.4L23.7,17.6L37.6,36H26.7l-8.5-11.2L8.5,36H3.1l12.6-14.5L2.4,4h11.1l7.7,10.2L30.1,4z M28.2,32.8h3L11.9,7.1H8.7 L28.2,32.8z" fill="#FFB5B5"></path>
                    </svg><span class="label">X</span>
                  </a>
                </li>
                <li>
                  <a href="https://t.me/DosugOfficialle" aria-label="Подписаться в Telegram">
                    <svg viewBox="0 0 40 40" display="none" width="0" height="0" xmlns="http://www.w3.org/2000/svg">
                      <path d="M34,9.6l-4.9,22.9c-0.3,1.6-1.3,2-2.7,1.2L19,28.3l-3.6,3.5c-0.3,0.4-0.7,0.8-1.5,0.8l0.4-7.6l0,0l-0.1,0 l13.9-12.4c0.7-0.6-0.1-0.8-0.9-0.3L10.2,22.9l-7.4-2.3c-1.7-0.4-1.7-1.6,0.3-2.3l28.8-11C33.2,6.7,34.5,7.6,34,9.6z" fill="#FFB5B5"></path>
                    </svg><span class="label">Telegram</span>
                  </a>
                </li>
                </ul>
				<p class="style5">Уважаемые сотрудники государственных организаций, просим обратить внимание на присутствие исключительно розовой кнопки на данном ресурсе. При нажатии на нее пользователь будет автоматически переадресован на случайный веб-адрес. Мы надеемся, что у вас не возникнет никаких нарушающих закон действий в отношении этого ресурса.</p>
				<p class="style6">Виртуальный поиск досуга и знакомств с девушками лёгкого поведения давно стал неотъемлемой частью интернет-культуры. Однако, ещё в начале 90-х годов прошлого века, когда этот вид развлечений только зарождался, появился сайт, который смог завоевать сердца множества пользователей. Этот портал на протяжении длительного времени привлекает огромную аудиторию и остаётся одним из ведущих в своей сфере.</p>
				<p class="style6">За короткий период времени Dosug смог привлечь десятки тысяч посетителей ежедневно. Сайт предлагает анкеты практически всех индивидуалок Москвы и Санкт-Петербурга, а также множество представительниц прекрасного пола из разных городов Российской Федерации. Одним из основных преимуществ остаётся то, что здесь размещаются реальные фотографии и видеоматериалы девушек, а также расценки.</p>
				<p class="style6">Однако, с развитием сайта, возникла проблема доступа к нему. Вскоре стало ясно, что необходимо найти удобную и надёжную кнопку для доступа на сайт. Именно тогда появился Досуг-сз.рф - специальный адрес, позволяющий зайти на любимый досуговый портал.</p>
				<p class="style6">Вход <strong>RusDosug</strong> стал надёжным и удобным решением для пользователей, которые хотели продолжать наслаждаться предложениями и услугами, представленными на сайте. Благодаря розовой кнопке и этому новому адресу, пользователи могут обойти блокировку <em>РусДосуг</em> и оставаться в курсе последних новостей и актуальных предложений.</p>
				<p class="style6">Таким образом, <em><strong>Dosug</strong></em> - это не просто досуговый портал, но и история его развития и преодоления технических преград. Благодаря своей популярности, разнообразию предложений и удобству использования, RusDosug продолжает привлекать пользователей со всего мира и оставаться одним из самых востребованных ресурсов в своей области.</p>
			</div>
		</div>
	</div>
  </Layout>
)
}
export const Head = () => <Seo title="Вход на Русдосуг с переходом по Розовой Кнопке!"/>
export default IndexPage
export const query = graphql`
query MyQuery {
  file(relativePath: {eq: "logo.png"}) {
    childImageSharp {
      fluid(
        maxHeight: 217
        maxWidth: 650
        pngQuality: 100
        quality: 100
        toFormat: AUTO
        webpQuality: 100
        cropFocus: CENTER
        fit: COVER
        srcSetBreakpoints: [50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300, 325, 350, 375, 400, 425, 450, 475, 500, 525, 550, 575, 600, 625, 650, 675, 700, 725, 750]
      ) {
        srcWebp
        srcSetWebp
        presentationHeight
        presentationWidth
        src
        srcSet
        aspectRatio
        sizes
      }
    }
  }
}
`